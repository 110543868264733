import axios from 'axios'
import router from "@/router";
import Vue from 'vue';

// 创建可一个新的axios对象
const request = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,   // 后端的接口地址  ip:port
    timeout: 300000                          // 300s请求超时
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
// request 拦截器
request.interceptors.request.use(config => {
    const userItem = localStorage.getItem("xm-user");
    const user = userItem ? JSON.parse(userItem) : null;
    if (user && user.token) {
        config.headers['satoken'] = user.token;  // 如果用户信息存在并且包含token，则设置请求头
    }
    return config;
}, error => {
    console.error('request error: ' + error);
    return Promise.reject(error);
});


// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;

        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if (res.code === '401') {
            // 在控制台打印日志
            console.log("处理异常，清除存储的用户信息");
            // 清除本地存储的用户信息
            localStorage.removeItem('xm-user');
            // 引导用户重新登录
            router.push('/login').catch(err => {
                console.log('路由跳转错误', err);
            });
            // 提示用户
            Vue.prototype.$message.error('会话已过期。请重新登录。');
        }
        return res;
    },
    error => {
        if (error.response) {
            // 根据后端返回的状态码处理不同的情况
            if (error.response.status === 401) { // 未授权或token过期
                // 在控制台打印日志
                console.log("处理异常，清除存储的用户信息");
                // 清除本地存储的用户信息
                localStorage.removeItem('xm-user');
                // 引导用户重新登录
                router.push('/login').catch(err => {
                    console.log('路由跳转错误', err);
                });
                // 提示用户
                Vue.prototype.$message.error('会话已过期。请重新登录。');
            } else {
                // 其他错误处理
                let message = error.response.data && error.response.data.msg ? error.response.data.msg : '服务异常';
                Vue.prototype.$message.error(message);
            }
        } else if (error.request) {
            Vue.prototype.$message.error('服务无响应');
        } else {
            Vue.prototype.$message.error('请求异常');
        }
        // 返回拒绝的promise
        return Promise.reject(error);
    }
)


export default request